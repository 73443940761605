<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Course Grade") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('COURSE_GRADE_UPDATE') && isEditing && tab == 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('COURSE_GRADE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Details") }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <CourseField
                    showKey
                    v-model="form.course"
                    :label="$i18n.translate('Course')"
                    :rules="rules.course"
                    class="required"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'course')"
                    @input="$error.clearValidationError(errors, 'course')"
                  />
                  <ParticipantField
                    showKey
                    v-model="form.participant"
                    :label="$i18n.translate('Participant')"
                    :rules="rules.participant"
                    class="required"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'participant')"
                    @input="$error.clearValidationError(errors, 'participant')"
                  />
                  <v-text-field
                    name="grade"
                    label="Grade"
                    id="grade"
                    type="number"
                    min="0"
                    v-model="form.grade"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'grade')"
                    @input="$error.clearValidationError(errors, 'grade')"
                  ></v-text-field>
                  <DateTimePickerField
                    v-model="form.completedDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Completed Date')"
                    defaultTime="00:00"
                    :rules="rules.completedDate"
                    class="required"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="6">
                  <h2>Information</h2>
                  <ul>
                    <li>
                      A <b>{{ $i18n.translate("Course Grade") }}</b> is a grade achieved for a particular
                      <b>{{ $i18n.translate("Course") }}</b
                      >.
                    </li>
                    <li>
                      <b>{{ $i18n.translate("Grade") }}</b> is optional, but the <b>Passing Grade</b> is configured at
                      both the {{ $i18n.translate("Course") }} level and
                      {{ $i18n.translate("Promotion Course") }} level.
                    </li>
                    <li>
                      <b>{{ $i18n.translate("Completed Date") }}</b> is required. We don't support partial grades at
                      this time.
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import CourseField from "../../fields/CourseField.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import ParticipantField from "../../fields/ParticipantField.vue";

export default {
  name: "CourseGrade",
  metaInfo: {
    title: "Course Grade"
  },
  components: {
    ApiError,
    DateTimePickerField,
    ParticipantField,
    CourseField
  },
  data: function() {
    return {
      courseGradeId: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {},
      errors: {},
      rules: {}
    };
  },
  methods: {
    initRules() {
      this.rules = {
        course: [v => !!v || this.$i18n.translate("Course") + " is required"],
        participant: [v => !!v || this.$i18n.translate("Participant") + " is required"],
        completedDate: [v => !!v || this.$i18n.translate("Completed Date") + " is required"]
      };
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let serviceCall = null;

      let postForm = Object.assign({}, this.form);
      postForm.participant = this.$api.getSelfUrl("participants", this.form.participant);
      postForm.course = this.$api.getSelfUrl("courses", this.form.course);

      if (this.isNew) {
        serviceCall = this.$api.post("/api/courseGrades", postForm);
      } else {
        serviceCall = this.$api.patch("/api/courseGrades/" + this.$route.params.id, postForm);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
            this.courseGradeId = data.id;
          }
          this.fetchData();
        })
        .catch(error => {
          this.loading = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/courseGrades/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            return Promise.all([
              this.$api.getRelatedObject("participant", data).then(({ data }) => {
                this.form.participant = data;
              }),
              this.$api.getRelatedObject("course", data).then(({ data }) => {
                this.form.course = data;
              })
            ]);
          })
          .finally(() => {
            this.loading = false;
            this.breadcrumb();
          });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Course Grades",
            to: { name: "courseGrades" },
            exact: true
          },
          { text: "New Course Grade" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Course Grades",
            to: { name: "courseGrades" },
            exact: true
          },
          { text: "Update Course Grade" }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"])
  },

  mounted() {
    this.initRules();
    this.courseGradeId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
